import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Project } from '../projects/domain/project';
import { EmployeeConflict } from '../shared/models/employee-conflict';
import { PhaseWithEmployees } from '../projects/domain/phase-with-employees';

export interface AssignEmployeeData {
  startWork: string;
  endWork: string;
  hourlyWage: number;
  professionId: number;
}

@Injectable({
  providedIn: 'root',
})
export class AssignEmployeeService {
  private project = new BehaviorSubject<Project>(undefined);
  private phase = new BehaviorSubject<PhaseWithEmployees>(undefined);
  private employeeIds = new BehaviorSubject<string[]>([]);

  project$ = this.project.asObservable();
  phase$ = this.phase.asObservable();
  employeeIds$ = this.employeeIds.asObservable();

  constructor(private httpClient: HttpClient) {}

  setProject(project: Project) {
    this.project.next(project);
  }

  setPhase(phase: PhaseWithEmployees) {
    this.phase.next(phase);
  }

  setEmployeeIds(ids: string[]) {
    this.employeeIds.next(ids);
  }

  assignEmployeeToPhase(employeeIds: string[], projectId: string, phaseId: string) {
    const objects = employeeIds.map(i => ({ employeeId: i }));
    return this.httpClient.post(`${ environment.api }/project/${ projectId }/phase/${ phaseId }/employee`, {
      employees: [...objects],
    });
  }

  removeEmployeeFromPhase(employeeOnPhaseIds: string[], projectId: string, phaseId: string) {
    return this.httpClient.request('delete', `${ environment.api }/project/${ projectId }/phase/${ phaseId }/employee`,
      { body: { ids: employeeOnPhaseIds } });
  }

  adjustEmployeeInPhase(employeeOnPhaseIds: string[], projectId: string, phaseId: string, assignEmployeeData: Partial<AssignEmployeeData>) {
    return this.httpClient.put(`${ environment.api }/project/${ projectId }/phase/${ phaseId }/employee`,
      {
        ...assignEmployeeData,
        ids: employeeOnPhaseIds,
      },
    );
  }

  addEmployeeInPhaseNote(employeeOnPhaseId: string, projectId: string, phaseId: string, note: string) {
    return this.httpClient.request('post',`${environment.api}/project/${projectId}/phase/${phaseId}/employee/${employeeOnPhaseId}/note`, {
      body: { note }
    });
  }

  editEmployeeInPhaseNote(employeeOnPhaseId: string, projectId: string, phaseId: string, note: string) {
    return this.httpClient.put(`${environment.api}/project/${projectId}/phase/${phaseId}/employee/${employeeOnPhaseId}/note`, {
      note,
    });
  }

  removeEmployeeInPhaseNote(employeeOnPhaseId: string, projectId: string, phaseId: string) {
    return this.httpClient.delete(`${environment.api}/project/${projectId}/phase/${phaseId}/employee/${employeeOnPhaseId}/note`);
  }

  checkConflictsWithProject(projectId: string, phaseId: string, employeeIds: string[]): Observable<EmployeeConflict[]> {
    let params = new HttpParams();

    employeeIds.forEach((id: string) => {
      params = params.append(`employeeIds[]`, id);
    });

    return this.httpClient.get<EmployeeConflict[]>(`${ environment.api }/project/${ projectId }/phase/${ phaseId }/employee/conflict`,
      { params });
  }
}
